/* Large Version */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .welcome-content h2 {
        font-size: 42px;
    }
    .timelineBody {
        max-height: 545px;
    }
    .team-member-thumb {
        width: 150px;
        height: 150px;
    }
    .post-meta p a {
        margin-right: 15px;
    }
    .post-title h4 {
        font-size: 20px;
    }
    .single-team-member {
        padding: 20px;
    }
    .cd-intro.default-title > h2 {
        font-size: 42px;
    }
}

/* Medium Version */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .welcome_area {
        height: 700px !important;
    }
    .welcome_single_slider.height-900 {
        height: 700px;
    }
    .welcome-content h2 {
        font-size: 30px;
    }
    .cta-content h2 {
        font-size: 30px;
    }
    .timelineBody {
        max-height: 640px;
    }
    .price_table_text > h1 {
        font-size: 36px;
    }
    .price_table_text > p,
    .table_text_details > p {
        font-size: 13px;
    }
    .login-btn {
        margin: 30px;
    }
    .cd-intro.default-title > h2 {
        font-size: 36px;
    }
}

/* Mobile Version */

@media only screen and (max-width: 767px) {
    .login-btn {
        margin: 30px;
    }
    .welcome-content h2 {
        font-size: 24px;
    }
    .welcome-content p {
        font-size: 14px;
    }
    .dream-btn {
        min-width: 130px;
        padding: 0 20px;
        font-size: 10px;
    }
    .single_cool_fact {
        margin-bottom: 50px;
    }
    .classy-navbar {
        height: 60px;
        padding: .5em 0em;
    }
    .who-we-contant h4 {
        font-size: 24px;
    }
    .sonar {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    .cta-content h2 {
        font-size: 24px;
    }
    .cta-content p {
        font-size: 14px;
    }
    .timelineBox {
        margin-top: 100px;
    }
    .our-mission-area {
        height: 1000px !important;
    }
    .section-heading h2 {
        font-size: 24px;
    }
    .post-meta p a {
        margin-right: 15px;
    }
    .post-title h4 {
        font-size: 20px;
    }
    .contact_info_area {
        margin-top: 50px;
    }
    .footer-side-thumbnail {
        width: 100%;
    }
    .welcome_single_slider.height-900 {
        height: 600px;
    }
    .welcome_area.sliders {
        height: 600px !important;
    }
    .cd-intro.default-title > h2 {
        font-size: 24px;
    }
    .welcome_area.animated-headline {
        height: 600px !important;
    }
    .welcome_area.animated-headline {
        height: 600px !important;
    }
    .dream-btn.video-btn {
        min-width: 70px;
        padding: 0;
        font-size: 20px;
    }
    #scrollUp {
        bottom: 100px;
        right: 0;
    }
    .welcome-video-area {
        margin-top: 0;
    }
    .timelineBody .timeline {
        padding: 1em;
    }
    .timelineBody .timeline .timelineDate {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 50px;
                flex: 0 0 50px;
        padding: 0;
    }
    .footer-copywrite-info {
        text-align: center;
    }
    .breadcumb--con {
        padding-top: 60px;
    }
    .welcome-content {
        margin-top: 60px;
    }
    .sidebar-area {
        margin-top: 100px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .welcome_area {
        height: 1100px !important;
    }
    .welcome_area.none{
        height: 100vh !important;
    }
    .sonar {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    .welcome-content h2 {
        font-size: 30px;
    }
    .dream-btn {
        min-width: 160px;
        padding: 0 20px;
        font-size: 12px;
    }
    .single-team-member:after {
        width: 50%;
    }
   
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .welcome-content h2 {
        font-size: 36px;
    }
    .dream-btn {
        min-width: 160px;
        padding: 0 20px;
        font-size: 12px;
    }
    .cd-intro.default-title > h2 {
        font-size: 30px;
    }
    .single-team-member:after {
        width: 80%;
    }
}