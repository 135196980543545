/* ##### Accordians CSS ##### */

.HomeDemo3 .conuter-header h3{
    font-weight: 600;
    font-size: 24px;
    color: #fff;
}

.HomeDemo3 .dream-faq-area dl {
    width: 100%;
}

.HomeDemo3 .dream-faq-area dt {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 0;
    color: #fff;
    font-weight: 500;
}

.HomeDemo3 .dream-faq-area dt:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.HomeDemo3 .dream-faq-area dd {
    margin: 0;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.HomeDemo3 .ico-counter{
    background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%);
    padding: 40px;
    border-radius: 20px;
}

.HomeDemo3 .count-down .table-cell {
    position: relative;
    width: 25%
}
.HomeDemo3 .count-down .tab-val {
    width: 90%;
    font-size: 20px;
    font-weight: 500;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    background-color: #290571;
    color: #ffffff;
}
.HomeDemo3 .count-down .tab-metr {
    margin-top: 4px;
    font-size: 11px;
    margin-bottom: 15px;
    color: #ffffff;
}
@media (max-width: 480px) {
    .HomeDemo3 .count-down .tab-metr{
        font-size: 14px
    }
}

.HomeDemo3 .slideCountdown{
    display: flex !important;
    font-family: Courier;
    font-weight: bold;
    justify-content: center;
    color: #fff;
}

.HomeDemo3 .slide-block-time{
    font-size: 30px;
    min-width: 24%;
    margin: 0;
    float: left;
    font-size: 25px !important;
    width: 30px !important;
    margin: 0px !important;
    &:nth-child(2){
        margin: 0 10px !important;
    }

    &:nth-child(3){
        margin-right: 10px !important;
    }
}


.HomeDemo3 .wrap-stage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #290571;
    padding: 20px;
}

.HomeDemo3 .slide-block-time .wrap-stage > .stage{
    width: 20px;
    overflow: hidden;
    height: 40px;
    position: relative;
}

.HomeDemo3 .slide-block-time > .title{
    font-size: 10px;
    font-weight: normal;
    margin-top: 5px;
    text-transform: lowercase;
    // color: transparent;
}

.HomeDemo3 .ico-counter a {
    text-decoration: none !important;
}

.HomeDemo3 .ico-progress span {
    color: #370779;
    font-size: 12px;
    font-weight: 700;
    padding-top: 7px;
    display: inline-block;
}

.HomeDemo3 .ico-progress .current-progress{
    width: 100%;
    height: 20px;
    position: relative;
    background: rgba(191, 191, 191, 0.6);
    border-radius: 7px;
}

.HomeDemo3 .current-progress::before{
    content: "";
    position: absolute;
    width: 1px;
    height: 26px;
    bottom: -5px;
    left: 12%;
    background: #fff;
}

.HomeDemo3 .list-percent{
    margin-top: 1rem;
    margin-bottom:0;
}

.HomeDemo3 .ico-progress li{
    font-size: 18px;
    font-weight: 400;
}

.HomeDemo3 .ico-progress li.title{
    float: left;
    padding-left: 30px;
    font-weight: 500;
    color: #fff;
}

.HomeDemo3 .ico-progress li.strength{
    float: right;
    font-weight: 500;
    color: #fff;
}

.HomeDemo3 .clearfix::after{
    display: block;
    clear: both;
    content: "";
}

.HomeDemo3 .doc-element{
    background-color: #1d025c;
    border-radius: 4px;
    border-bottom: 2px solid #25cbd3;
    position: relative;
    transition: .5s;
    cursor: pointer;
    padding: 20px;
    margin-top: 20px;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.HomeDemo3 .doc-element:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    transition: .3s;
    right: 15px;
    width: 27px;
    height: 34px;
    background: url(../../assets/img/svg/pdf.svg) 50% no-repeat;
    background-size: contain;
}
.HomeDemo3 .doc-element:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    transition: .3s;
    right: 15px;
    width: 27px;
    height: 30px;
    background: url(../../assets/img/svg/view.svg) 50% no-repeat;
    background-size: contain;
    opacity: 0;
}
.HomeDemo3 .doc-element .document-entry .title {
    font-size: 14px;
    color: #fff;
    line-height: 1.35;
}
.HomeDemo3 .doc-element:hover {
    background-color: #25cbd3;
}
.HomeDemo3 .doc-element:hover:after {
    opacity: 1;
}

.HomeDemo3 .token-distribution h2{
    color: #fff;
}

.HomeDemo3 .our-mission-content h6{
    color: #fff;   
}