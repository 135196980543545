
/* ##### 3.0 Wellcome Area CSS ##### */

.HomeDemo3 .welcome_area {
     padding-bottom: 30px;
     display: flex;
     align-items: center;
     justify-content: center;
     padding-bottom: 30px;
     background:black;
     z-index: 10;
}

.HomeDemo3 .Home1WelcomeAreaIMG{
    min-height: 100%;
    background: url(../../assets/img/bg-img/bg-3.jpg) no-repeat center center fixed !important;
}

.HomeDemo3 .hero-content {
    display: flex;
    align-items: center;
}

.dream-btn-group {
    display: flex;
    gap: 16px;
}

.dream-btn {
    position: relative !important;
    z-index: 1 !important;
    min-width: 160px !important;
    height: 48px !important;
    line-height: 48px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
    display: inline-block !important;
    padding: 0 20px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    background-size: 200% auto !important;
    color: #fff !important;
    box-shadow: 0 3ox 20px rgba(0, 0, 0, 0.1) !important;
    border-radius: 100px !important;
    background-image: -webkit-linear-gradient(left, #21d397 0%, #7450fe 51%, #21d397 100%);
    background-image: linear-gradient(to right, #21d397 0%, #7450fe 51%, #21d397 100%);
    -webkit-transition: all 500ms !important;
    transition: all 500ms !important;
}

.dream-btn:hover {
    background-position: right center;
    color: #fff;
}

.dream-btn.selected {
    background: transparent;
}

.HomeDemo3 .hero-content.globe{
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.75));
}
.HomeDemo3 .hero-content.transparent{
    background: transparent;
}
.HomeDemo3 .hero-content.dark-blue{
    background: rgb(33, 0, 67, 0.9);
}
.HomeDemo3 .hero-content .ico-counter{
    margin: 100px 7% 0;
}
@media (max-width: 992px){
    .hero-content .ico-counter,
    .HomeDemo3 .hero-content .service-img-wrapper .image-box{
        margin-top: 0px !important
    }
    
    .HomeDemo3 .hero-content>.container>.row {
        gap: 30px;
    }
}
.HomeDemo3 .hero-content .service-img-wrapper .image-box .rings{
    position: absolute;
    top: 50%;
    z-index: -1;
    margin: 0 auto;
    left: 50% !important;
    width: 120%;
    transform: translate(-50%, -50%);
    animation: unset;
    -webkit-animation: unset;
}

.HomeDemo3 .welcome_area .chart-div {
    max-width: 100%;
    height: 300px;
}

.HomeDemo3 .welcome_area .timeframe-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
}

.HomeDemo3 .welcome_area .timeframe-button-group .timeframe-button {
    border: 0.3px solid #665533;
    background-color: transparent;
    color: #665533;
    padding: 4px 10px;
    border-left: none;
}

.HomeDemo3 .welcome_area .timeframe-button-group .timeframe-button.selected {
    background-color: #665533;
    color: #FFFFFF;
}

.HomeDemo3 .welcome_area .timeframe-button-group .timeframe-button:first-of-type {
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
}

.HomeDemo3 .welcome_area .timeframe-button-group .timeframe-button:last-of-type {
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
    border-left: none;
}

.HomeDemo3 .welcome_area h3 {
    color: #FFFFFF;
}

tspan {
    font-size: 9px;
}

.HomeDemo3 .adxx-info {
    color: #FFFFFF;
}

.HomeDemo3 .welcome-content {
    position: relative;
    z-index: 1;
    margin-top: 120px;
}

.HomeDemo3 .promo-section {
    margin-bottom: 30px;
}
.HomeDemo3 .integration-link {
    padding: 8px 10px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    letter-spacing: .58px;
    font-size: 14px;
    line-height: 24px;
    color: #d5d5ea
}
.HomeDemo3 .integration-link:hover {
    color: #fff;
    text-decoration: none;
}
.HomeDemo3 .integration-link:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to bottom right,#766ee6 0,#3540b0);
    border-radius: 100px;
    opacity: .5;
}
.HomeDemo3 .integration-icon {
    margin: 0 5px 0 0;
}
.HomeDemo3 .integration-text {
    margin: 0 7px 0 0;
}
.HomeDemo3 .integration-link>* {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: top;
}

.HomeDemo3 .main-ilustration{
    position: relative;
    background: url(../../assets/img/svg/head-bg-1.svg) no-repeat center 65%;
    height: 100vh;
    background-size: contain;
}
.HomeDemo3 .main-ilustration-2{
    position: relative;
    background: url(../../assets/img/core-img/robot-2.png) no-repeat center bottom;
    height: 100vh;
    bottom: 0;
    background-size: contain;
}
.HomeDemo3 .main-ilustration-3{
    position: relative;
    background: url(../../assets/img/core-img/robot-2.png) no-repeat center bottom;
    height: 100vh;
    bottom: 0;
    background-size: cover;
}
.HomeDemo3 .main-ilustration-4{
    position: relative;
    background: url(../../assets/img/svg/head-bg-1.svg) no-repeat center 65%;
    height: 100vh;
    background-size: cover;
}
@media (max-width: 1200px){
    .HomeDemo3 .main-ilustration-3{
        background-size: contain;
        background-position: left;
    }
    .HomeDemo3 .main-ilustration-3 .inovation{
        right: 0% !important;
        top: 5%;
        display: inline-table;
    }
    .HomeDemo3 .main-ilustration-3 .inovation.num2{
        right: 5%;
        top: 27%;
    }
}
@media (max-width: 767px){
    .HomeDemo3 .main-ilustration-3{
        background-size: contain;
        background-position: left;
    }
    .HomeDemo3 .main-ilustration-3 .inovation{
        right: 0% !important;
        top: 42%;
        display: inline-table;
    }
    .HomeDemo3 .main-ilustration-3 .inovation.num2{
        right: 5%;
        top: auto;
    }
    .HomeDemo3 .welcome-small-wrapper{
        position: absolute;
        width: 38%;
    }
    .HomeDemo3 .welcome-content-small{
        position: absolute;
        top: -30px;
    }
    .HomeDemo3 .welcome-content.ill{
        margin-top: 120px !important
    }
}
@media (max-width: 480px){

    .HomeDemo3 .welcome-content {
        vertical-align: middle;
        margin-top: 100px !important

    }



    .HomeDemo3 .main-ilustration-3{
        display: none;
    }
    .HomeDemo3 .welcome-small-wrapper{
       display: none;
    }

    .dream-btn-group {
        flex-direction: column;
        justify-content: center;
    }
    
}
.HomeDemo3 .main-ilustration-2:before{
    content: '';
    position: absolute;
    width: 30%;
    height: 1000px;
    left: 50%;
    top: -30%;
    transform: rotate(21deg);
    background: rgba(0, 0, 128, 0.7);
}
.HomeDemo3 .main-ilustration-3 .inovation{
    position: absolute;
    bottom: 10%;
    right: -15%;
    width: 40%;
    background: #0d003b;
    padding: 20px;
}
.HomeDemo3 .inovation.num2{
    right: -57%;
}
.HomeDemo3 .inovation h5{
    padding-left: 25px;
    position: relative;
}
.HomeDemo3 .inovation h5:before{
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    width: 15px;
    height: 2px;
    background: #25cbd3;
}
.HomeDemo3 .inovation p{
    font-size: 12px;
    margin-bottom: 0;
}

@media (min-width: 1200px){
    .HomeDemo3 .main-ilustration {
        min-width: 650px;
    }
}

.HomeDemo3 .welcome-content h2 {
    font-size: 52px;
    font-weight: 500;
    margin-bottom: 20px;
}
.HomeDemo3 .welcome-content h1 {
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #fff;
}
.HomeDemo3 .welcome-content h1.artficial{
    font-size: 38px
}
.HomeDemo3 .welcome-content p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
}

.HomeDemo3 .who-we-contant h4{
    color: #fff;
}

.HomeDemo3 p.artficial{
    padding-left: 20px;
    border-left: 1px solid #fff;
    position: relative;
}
.HomeDemo3 p.artficial:before{
    content: '';
    position: absolute;
    left: -5px;
    top: -8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #fff
}
.HomeDemo3 .welcome-content-small{
    margin-bottom: 50px
}
.HomeDemo3 .welcome-content-small h4{
    margin-bottom: 20px
}
.HomeDemo3 .welcome-content-small p.artficial{
    font-size: 12px
}
.HomeDemo3 .welcome-video-area {
    position: relative;
    z-index: 1;
    margin-top: 90px;
}

.HomeDemo3 .welcome-video-area .welcome-thumb {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    border: 1px solid #25cbd3;
    border-radius: 15px;
    padding: 0px 30px 0;
}

.HomeDemo3 .welcome-video-area .welcome-thumb img {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    position: relative;
    top: -30px
}

.HomeDemo3 .video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 40px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    color: #4834d4;
}

.HomeDemo3 .video-btn {
    background-color: #4834d4;
    width: 70px;
    height: 70px;
    color: #fff;
    border-radius: 50%;
    line-height: 70px;
    padding: 0;
    text-align: center;
    min-width: 70px;
    font-size: 24px;
}

.HomeDemo3 .video-btn:hover {
    background-color: #4834d4;
    color: #fff;
}




.HomeDemo3 .fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.HomeDemo3 .fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .HomeDemo3 .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .HomeDemo3 .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}
.HomeDemo3 .welcome_area.video .desc{
    max-width: 60%;
    margin: 0 auto
}
@media (max-width: 767px) {
  .HomeDemo3 .fullscreen-bg {
    // background: url('video/video-frame.png') center center / cover no-repeat;
  }
   .HomeDemo3 .welcome_area.video .desc{
        max-width: 80%;
        margin: 0 auto
    }
  .HomeDemo3 .fullscreen-bg__video {
    display: none;
  }
}
.HomeDemo3 .video-bg-illution{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #010111;
    z-index: 50;
    opacity: .7;
}
